/*eslint-env node*/
function categoryNameToSlug (category) {
  return category.replace(/[^a-z0-9]/ig, '-').toLowerCase();
}

exports.categoryNameToSlug = categoryNameToSlug;

exports.linkResolver = function linkResolver (doc) {
  if (doc.type === 'homepage') {
    return '/';
  }

  if (doc.type === 'articles') {
    if (doc.data?.category) {
      let categoryPath = categoryNameToSlug(doc.data.category);
      return `/articles/${categoryPath}/${(doc.uid ?? doc.slug).replace(/\./g, '/')}`;
    }

    return `/articles/${(doc.uid ?? doc.slug).replace(/\./g, '/')}`;
  }

  if (doc.uid ?? doc.slug) {
    return `/${(doc.uid ?? doc.slug).replace(/\./g, '/')}`;
  }

  throw new Error(`Invalid type for link: ${JSON.stringify(doc)}`);
};
