/* global process */
import React from "react";
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import { SliceZone } from '../components';
import { linkResolver, transformFieldName } from '../utils/prismic-config';

function LoginPageTemplate ({ data, path, pageContext }) { //eslint-disable-line no-unused-vars
  const doc = data.prismicLoginPage.data;

  return (
    <>
      <Helmet>
        <title>{doc.title.text}</title>
        <meta name="og:title" content={doc.title.text} />
        <meta name="description" content={doc.metaDescription?.text} />
        <meta name="og:description" content={doc.metaDescription?.text} />
        <meta name="og:image" content={doc.image?.fixed?.src} />
      </Helmet>

      <SliceZone sliceZone={doc.body} url={pageContext.url} />
    </>
  );
}

export const query = graphql `
query LoginPageQuery($id: String) {
  prismicLoginPage(id: { eq: $id }) {
    _previewable

    data {
      title { text }
      metaDescription
      image {
        fixed { src }
      }

      body {
        __typename
        ... on PrismicLoginPageDataBodyLoginLanding {
          ...loginPageLoginLandingFields
        }
        ... on PrismicLoginPageDataBodyLoginForm {
          ...loginPageLoginFormFields
        }
        ... on PrismicLoginPageDataBodyFuelSurcharges {
          ...loginPageFuelSurchargesFields
        }
        ... on PrismicLoginPageDataBodyRelatedArticles {
          ...loginPageRelatedArticlesFields
        }
        ... on PrismicLoginPageDataBodyCustomCode {
          ...loginPageCustomCodeFields
        }
      }
    }
  }
}
`;

export default withPrismicPreview(LoginPageTemplate, [
  {
    repositoryName: process.env.PRISMIC_REPOSITORY,
    linkResolver, transformFieldName
  }
]);
