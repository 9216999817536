/*eslint-env node*/
const { createElement } = require('react');
const { renderToStaticMarkup } = require('react-dom/server');

module.exports = function HtmlSerializer (type, element, content, children, key) {
  if (type === 'paragraph' && element?.spans?.length) {
    const span = element.spans.find((s) => {
      return s.type === 'label' && s.start === 0 && s.end === element.text?.length;
    });

    if (span?.data?.label) {
      let className = span.data.label.replace(/[^a-z0-9-]+/ig, () => '-').toLowerCase();
      return renderToStaticMarkup(createElement('p', {
        className, key,
        dangerouslySetInnerHTML: { __html: children }
      }));
    }
  }

  if (type === 'label' && element?.data?.label === 'Info-Tooltip') {
    let className = element.data.label.replace(/[^a-z0-9-]+/ig, () => '-').toLowerCase();

    const theSpan = renderToStaticMarkup(createElement('span', {
      className: `${className}-inner`,
      dangerouslySetInnerHTML: { __html: children }
    }));

    return renderToStaticMarkup(createElement('span', {
      className, key,
      dangerouslySetInnerHTML: { __html: theSpan }
    }));
  }

  return null;
};
